<template>
	<div>
		<loading
			:active="isLoading"
			:can-cancel="true"
			:on-cancel="onCancel"
			:is-full-page="true"
		/>

		<b-row>
			<b-col xl="12" md="12" v-if="userType != 'ADMIN'">
				<div v-if="sellerVerified == false">
					<b-alert variant="primary" show>
						<div class="alert-body">
							<span
								>Hey {{ userInfo.user_firstname }} ! Your account is not
								verified yet, kindly verify your account.</span
							>
							<b-button
								class="ml-2"
								size="sm"
								variant="primary"
								@click="sendVerification()"
							>
								Send Verification Link
							</b-button>
						</div>
					</b-alert>
				</div>

				<!-- <b-alert variant="primary" show v-if="!sellerPayoutVerified">
          <div class="alert-body">
            <span
              >Hey {{ userInfo.user_firstname }} ! Please connet your payout
              account now.</span
            >
            <b-button
              class="ml-2"
              size="sm"
              variant="primary"
              @click="
                $router.push({
                  name: `account-setting`,
                })
              "
            >
              Connect Now
            </b-button>
          </div>
        </b-alert> -->
			</b-col>
		</b-row>

		<validation-observer ref="itemFormsValidator">
			<b-card no-body class="p-2">
				<!-- <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="$router.go(-1)"
            >Back</b-button
          >
        </div> -->
				<!-- v-if="userType == 'ADMIN' -->
				<b-col sm="6" lg="6" md="6" v-if="userType == 'ADMIN'">
					<b-form-group description="Select Seller" class="mb-1">
						<v-select
							v-model="sellerSelected"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="user_email"
							:options="sellerList"
							placeholder="Seller"
						/>
					</b-form-group>
				</b-col>

				<b-form
					@submit.prevent="repeateAgain"
					ref="form"
					enctype="multipart/form-data"
					method="post"
				>
					<input
						v-show="false"
						ref="image-input"
						type="file"
						accept="image/*"
						@change="uploadNewImage($event)"
					/>

					<app-collapse accordion :type="collapseType">
						<app-collapse-item
							v-for="(item, index) in item_forms"
							:id="index"
							:key="'inout-card-s' + index"
							:title="'Item ' + (index + 1)"
							:isVisible="true"
						>
							<div no-body class="p-1 item_forms_card shadow-none" ref="row">
								<b-row>
									<b-col md="6">
										<b-form-group>
											<b-button-group class="w-100">
											<!-- <div
												variant="outline-primary"
												v-ripple.400="'rgba(113, 102, 240, 0.15)'"
												v-bind:class="[
													item.itemType == 'refurbish' ? 'active' : '',
												]"
												v-on:click="changeItemType('sell', index)"
											>
												SELL YOUR ITEM
											</div> -->
											<b-button
												v-ripple.400="'rgba(113, 102, 240, 0.15)'"
												v-bind:class="[
													item.itemType == 'sell' ? 'active' : '',
												]"
												v-on:click="changeItemType('sell', index)"
												variant="outline-primary"
												>
												SELL YOUR ITEM
											</b-button>
											<b-button
												v-ripple.400="'rgba(113, 102, 240, 0.15)'"
												v-bind:class="[
													item.itemType == 'refurbish' ? 'active' : '',
												]"
												v-on:click="changeItemType('refurbish', index)"
												variant="outline-primary"
												>
												REFURBISH YOUR ITEM
											</b-button>
											</b-button-group>
										</b-form-group>
									</b-col>

									<b-col md="6"></b-col>

									<!-- NEW IMAGES CARDs -->
									<b-col md="6" sm="3" lg="2" xl="2" xs="2">
										<div
											class="shadow-none choose-images-card"
											v-if="item.frontImage == ''"
										>
											<a class="fileContainer">
												<h5 @click="setImgType('FRONT', index)">
													* Front Image
												</h5>
											</a>

											<feather-icon
												icon="InfoIcon"
												style="color: #a96a37; top: 10%"
												class="info-icon image-required-info"
												v-b-popover.hover.top="'Front Image is required'"
												v-if="item.frontImageRequired"
											/>
										</div>

										<div
											class="shadow-none choose-images-card"
											v-if="item.frontImage != ''"
										>
											<b-img
												:ref="'front_image' + index"
												rounded
												:src="item.frontImage"
											/>
											<span class="delete"
												><span
													@click="removeNewImage('FRONT', index)"
													class="remove-icon"
													>Remove</span
												></span
											>
										</div>
									</b-col>

									<b-col md="6" sm="3" lg="2" xl="2" xs="2">
										<div
											class="shadow-none choose-images-card"
											v-if="item.backImage == ''"
										>
											<a class="fileContainer">
												<h5 @click="setImgType('BACK', index)">* Back Image</h5>
											</a>

											<feather-icon
												icon="InfoIcon"
												style="color: #a96a37; top: 10%"
												class="info-icon image-required-info"
												v-b-popover.hover.top="'Back Image is required'"
												v-if="item.backImageRequired"
											/>
										</div>

										<div
											class="shadow-none choose-images-card"
											v-if="item.backImage != ''"
										>
											<b-img
												:ref="'back_image' + index"
												rounded
												:src="item.backImage"
											/>
											<span class="delete"
												><span
													@click="removeNewImage('BACK', index)"
													class="remove-icon"
													>Remove</span
												></span
											>
										</div>
									</b-col>

									<b-col md="6" sm="3" lg="2" xl="2" xs="2">
										<div
											class="choose-images-card"
											v-if="item.insideImage == ''"
										>
											<a class="fileContainer">
												<h5 @click="setImgType('INSIDE', index)">
													* Side Image
												</h5>
											</a>

											<feather-icon
												icon="InfoIcon"
												style="color: #a96a37; top: 10%"
												class="info-icon image-required-info"
												v-b-popover.hover.top="'Side Image is required'"
												v-if="item.sideImageRequired"
											/>
										</div>

										<div
											class="choose-images-card"
											v-if="item.insideImage != ''"
										>
											<b-img
												:ref="'inside_image' + index"
												rounded
												:src="item.insideImage"
											/>
											<span class="delete"
												><span
													@click="removeNewImage('INSIDE', index)"
													class="remove-icon"
													>Remove</span
												></span
											>
										</div>
									</b-col>

									<b-col md="6" sm="3" lg="2" xl="2" xs="2">
										<div
											class="choose-images-card"
											v-if="item.labelImage == ''"
										>
											<a class="fileContainer">
												<h5 @click="setImgType('LABEL', index)">
													* Label Image
												</h5>
											</a>

											<feather-icon
												icon="InfoIcon"
												style="color: #a96a37; top: 10%"
												class="info-icon image-required-info"
												v-b-popover.hover.top="'Label Image is required'"
												v-if="item.labelImageRequired"
											/>
										</div>

										<div
											class="choose-images-card"
											v-if="item.labelImage != ''"
										>
											<b-img
												:ref="'label_image' + index"
												rounded
												:src="item.labelImage"
											/>
											<span class="delete"
												><span
													@click="removeNewImage('LABEL', index)"
													class="remove-icon"
													>Remove</span
												></span
											>
										</div>
									</b-col>

									<b-col md="6" sm="3" lg="2" xl="2" xs="2">
										<div
											class="choose-images-card"
											v-if="item.defectImage == ''"
										>
											<a class="fileContainer">
												<h5 @click="setImgType('DEFECT', index)">
													Defect Image (Optoinal)
												</h5>
											</a>
										</div>

										<div
											class="choose-images-card"
											v-if="item.defectImage != ''"
										>
											<b-img
												:ref="'defect_image' + index"
												rounded
												:src="item.defectImage"
											/>
											<span class="delete"
												><span
													@click="removeNewImage('DEFECT', index)"
													class="remove-icon"
													>Remove</span
												></span
											>
										</div>
									</b-col>

									<!-- Offset Col-4 -->
									<b-col md="2"></b-col>

									<!-- MainCategory -->
									<b-col md="4">
										<b-form-group>
											<div class="custom-container">
												<label class="m-0" for="disclaimer">Category</label>
												<validation-provider
													#default="{ errors }"
													name="MainCategory"
													rules="required"
												>
													<v-select
														v-model="item.mainCategorySelected"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														label="MainCategory"
														:options="mainCategory"
														placeholder="* Select Category"
														@input="chooseMaincategory"
													/>

													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</div>
										</b-form-group>
									</b-col>

									<!-- Product - Item Type -->
									<b-col md="4">
										<b-form-group>
											<label class="m-0" for="disclaimer">Item Type</label>
											<validation-provider
												#default="{ errors }"
												name="ItemType"
												rules="required"
											>
												<!-- <v-select
                          v-model="item.typeSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="tag_name"
                          :options="clothType"
                          placeholder="* Item Type"
                        /> -->

												<v-select
													id="item-type"
													v-model="item.typeSelected"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													label="ItemType"
													:disabled="currentSubCategory.length == 0"
													:options="currentSubCategory"
													placeholder="* Item Type"
												/>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<!-- Designer Name -->
									<b-col md="4">
										<b-form-group>
											<label class="m-0" for="disclaimer">Designer</label>
											<validation-provider
												#default="{ errors }"
												name="Designer"
												rules="required"
											>
												<v-select
													v-model="item.designerSelected"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													label="tag_name"
													:options="designersList"
													placeholder="* Designer"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<!-- Condition -->
									<b-col md="4">
										<b-form-group>
											<div class="custom-container">
												<label class="m-0" for="disclaimer">Condition</label>
												<validation-provider
													#default="{ errors }"
													name="Condition"
													rules="required"
												>
													<v-select
														v-model="item.conditionSelected"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														label="tag_name"
														:options="conditionsList"
														placeholder="* Select Condition"
													/>

													<feather-icon
														icon="InfoIcon"
														class="info-icon info_icon_1 mr-3"
														v-b-modal.condition-modal
													/>

													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</div>
										</b-form-group>
									</b-col>

									<!-- Color -->
									<b-col md="4">
										<b-form-group>
											<label class="m-0" for="disclaimer">Color</label>
											<validation-provider
												#default="{ errors }"
												rules="required"
												name="Color"
											>
												<v-select
													v-model="item.color"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													:options="colors"
													label="title"
													placeholder="* Color"
												>
													<!-- <span> {{ title }}</span> -->
												</v-select>

												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col md="4" v-if="item.itemType === 'sell'">
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Original Purchase Price (Approx)</label
											>
											<b-row>
												<b-col cols="3" class="mr-0 pr-0">
													<b-form-select
														v-model="item.currenciesAmountSelected"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														label="Currency"
														placeholder="* Currency"
														@change="
															getSelectedCurrency(
																item.currenciesAmountSelected,
																index
															)
														"
													>
														<b-form-select-option
															v-for="(item_data, index) in $store.state.home
																.currency"
															:key="'currency_' + index"
															:value="item_data.c_id"
														>
															{{ item_data.c_symbol }}
														</b-form-select-option>
													</b-form-select>
												</b-col>

												<b-col cols="9" class="ml-0 pl-0">
													<validation-provider
														#default="{ errors }"
														name="Original Purchase Price"
														rules="required"
													>
														<div class="custom-container">
															<feather-icon
																icon="InfoIcon"
																class="info-icon"
																v-b-popover.hover.top="
																	'Please provide approximate Purchase Price'
																"
															/>
															<b-form-input
																placeholder="* Original Purchase Price"
																v-model="item.amount"
															/>
														</div>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-col>
											</b-row>
										</b-form-group>
									</b-col>

									<b-col md="4">
										<b-form-group>
											<div class="custom-container">
												<label class="m-0" for="disclaimer">Size</label>
												<validation-provider
													#default="{ errors }"
													name="Size"
													rules="required"
												>
													<v-select
														v-model="item.sizes"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														label="Size"
														:options="cloth_sizes"
														placeholder="* Select Size"
													/>

													<feather-icon
														v-if="item.mainCategorySelected == 'Women' || item.mainCategorySelected == 'Men'"
														icon="InfoIcon"
														class="info-icon info_icon_1 mr-3"
														@click="showSizeModal(index)"
													/>
													<small class="text-danger">{{ errors[0] }}</small>
												</validation-provider>
											</div>
										</b-form-group>
									</b-col>

									<b-col md="4">
										<b-form-group
											label-for="countries-select"
											invalid-feedback="Country is required"
										>
											<label class="m-0" for="disclaimer">Country</label>
											<validation-provider
												#default="{ errors }"
												name="Country"
												rules="required"
											>
												<v-select
													id="countries-select"
													v-model="item.countryModel"
													label="name"
													:options="countries"
													@input="showCity"
													required
													placeholder="* Item Pickup Country"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col md="4">
										<b-form-group
											label-for="city-select"
											invalid-feedback="City is required"
										>
											<label class="m-0" for="disclaimer">City</label>
											<validation-provider
												#default="{ errors }"
												name="Country"
												rules="required"
											>
												<v-select
													id="city-select"
													v-model="item.cityModel"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													label="name"
													:disabled="city_list.length == 0"
													:options="city_list"
													required
													placeholder="* Item Pickup City"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<!-- Upload Receipt [IMAGE -or- PDF] -->
									<b-col md="4" v-if="item.itemType === 'sell'">
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Upload Purchase Receipt (Optional)</label
											>
											<b-form-file
												type="file"
												name="receipt"
												accept="image/*, .pdf"
												:id="'receipt-' + index"
												placeholder="Upload Purchase Reciept (Optional)"
												v-on:change="uploadReceipt($event, index)"
											/>
										</b-form-group>
									</b-col>

									<!-- Purchase Year -->
									<b-col md="4" v-if="item.itemType === 'sell'">
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Purchase Year (Approx)</label
											>
											<div class="custom-container">
												<feather-icon
													icon="InfoIcon"
													class="info-icon"
													v-b-popover.hover.top="
														'Please provide an approximate Purchase Year'
													"
												/>
												<b-form-input
													type="number"
													onKeyPress="if(this.value.length==4) return false;"
													maxlength="4"
													minlength="4"
													v-model="item.date_of_purchase"
													placeholder="Purchase Year"
												/>
											</div>
										</b-form-group>
									</b-col>

									<!-- Product Description -->
									<b-col md="12" v-if="item.itemType === 'sell'">
										<b-form-group>
											<label class="m-0" for="disclaimer">Description</label>
											<validation-provider
												#default="{ errors }"
												name="Description"
												rules="required"
											>
												<b-form-input
													v-model="item.product_description"
													placeholder="* Product Description - Eg: Cape-effect belted crepe mini dress"
												/>
												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<b-col
										sm="12"
										md="12"
										lg="12"
										v-if="item.itemType === 'selll'"
									>
										<b-row>
											<b-col sm="12" md="12" lg="12">
												<div class="demo-inline-spacing">
													<b-form-checkbox
														v-model="item.charitySelected"
														class="custom-control-primary"
													>
														Do you wish to Donate your Item?
													</b-form-checkbox>

													<v-select
														v-if="item.charitySelected"
														style="width: 300px"
														v-model="item.charity"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														:options="charities"
														label="title"
														placeholder="* Select Charity"
													>
													</v-select>
												</div>
											</b-col>
											<b-col
												sm="12"
												md="12"
												lg="12"
												v-if="item.charitySelected"
											>
												<div class="demo-inline-spacing">
													<b-form-checkbox
														v-model="item.celebritySelected"
														class="custom-control-primary"
													>
														Is the item from a Celebrity Closet?
													</b-form-checkbox>

													<v-select
														v-if="item.celebritySelected"
														style="width: 300px"
														v-model="item.celebrity"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														:options="celebrities"
														label="title"
														placeholder="* Select Celebrity"
													>
													</v-select>

													<!-- <b-button
                            class=""
                            variant="primary"
                            v-b-modal.modal-lg-choose-celebrity
                          >
                            {{ keyword ? keyword : "Choose Celebrity" }}
                          </b-button> -->
												</div>
											</b-col>
										</b-row>
									</b-col>

									<!-- What customisation you'd like? -->
									<b-col md="3" v-if="item.itemType === 'refurbish'">
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Choose Customisation</label
											>
											<v-select
												v-model="item.service"
												:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
												label="service_name"
												:options="$store.state.home.services"
												placeholder="What customisation you'd like?"
												@input="setServiceId($event, index)"
											/>
										</b-form-group>
									</b-col>

									<!-- Revivify Service Amount -->
									<b-col
										md="3"
										v-if="item.service_id && item.itemType === 'refurbish'"
									>
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Revivify Base Service Amount</label
											>
											<b-input-group>
												<b-input-group-prepend>
													<b-form-select
														v-model="item.currenciesBudgetSelected"
														:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
														label="Currency"
														:options="currencies"
														placeholder="Currency"
														@change="convertCurrency(index)"
													/>
												</b-input-group-prepend>
												<!-- :value="item.service.service_base_price" -->
												<b-form-input
													placeholder="Revivify Base Service Amount"
													:value="
														item.service_converted_price == 0
															? Math.round(
																	item.service.service_base_price
															  ).toFixed(2)
															: Math.round(
																	item.service_converted_price
															  ).toFixed(2)
													"
													disabled
												/>
											</b-input-group>
										</b-form-group>
									</b-col>

									<!-- What is your Budget Limit? -->
									<b-col
										md="3"
										v-if="item.service_id && item.itemType === 'refurbish'"
									>
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>* Maximum Budget Amount</label
											>
											<validation-provider
												#default="{ errors }"
												name="BudgetAmount"
												rules="required"
											>
												<b-input-group>
													<b-input-group-prepend>
														<b-button variant="outline-primary">
															{{ item.currenciesBudgetSelected }}
														</b-button>
													</b-input-group-prepend>
													<b-form-input
														type="number"
														v-model.number="item.budget"
														placeholder="What is your Budget Limit?"
														v-model="item.budget"
													/>
												</b-input-group>
												<small class="text-danger">{{ errors[0] }}</small>
											</validation-provider>
										</b-form-group>
									</b-col>

									<!-- Preferred Date of Delivery -->
									<b-col md="5" v-if="item.itemType === 'refurbish'">
										<b-form-group>
											<label class="m-0" for="disclaimer"
												>Note: Date of delivery 2 weeks from now</label
											>
											<b-form-datepicker
												id="example-datepicker"
												v-model="item.delivery_date"
												placeholder="Preferred Date of Delivery"
												:min="min"
											/>
										</b-form-group>
									</b-col>

									<b-col md="2" v-if="index != 0">
										<b-button
											variant="secondary"
											type="submit"
											class="btn-block"
											@click="removeItem(index)"
										>
											Remove
										</b-button>
									</b-col>
								</b-row>
							</div>
						</app-collapse-item>
					</app-collapse>

					<b-row class="mt-2">
						<b-col md="2">
							<b-button variant="primary" type="submit" class="btn-block mt-1">
								Add Item <feather-icon icon="PlusIcon" />
							</b-button>
						</b-col>

						<b-col md="2">
							<!-- <b-button
                class="btn-block revivify-success mt-1"
                v-b-modal.term_condition_accept
                v-if="imagesValidation"
              >
                Submit Item
              </b-button> -->
							<b-button
								class="btn-block revivify-success mt-1"
								@click="submitForm('validate')"
							>
								Submit Item
							</b-button>
						</b-col>

						<b-col md="2">
							<b-button
								variant="secondary"
								class="btn-block mt-1"
								@click="draftForm('draft')"
							>
								Save as Draft
							</b-button>
						</b-col>
					</b-row>

					<!-- submit accept term & condition modal -->

					<b-modal
						id="term_condition_accept"
						ref="ref_term_condition_accept"
						ok-only
						ok-title="Accept"
						modal-class="modal-primary"
						centered
						title="Term and Condition"
						hide-footer
					>
						<!-- {{selected_tnc+"Happy"}} -->
						<b-card-text>
							<b-form-checkbox v-model="selected_tnc" value="A">
								I confirm I have read, understood and agree to the seller
								<b-link href="https://www.revivify.com/terms-conditions/"
									>Terms &amp; Conditions</b-link
								>
							</b-form-checkbox>
							<b-button
								v-ripple.400="'rgba(255, 255, 255, 0.15)'"
								variant="primary"
								:disabled="!selected_tnc"
								class="mt-2 float-right"
								@click="submitForm('save')"
								>Accept
							</b-button>
						</b-card-text>
					</b-modal>

					<b-modal
						ref="ref_size_modal_female"
						id="size_modal_female"
						cancel-variant="primary"
						ok-only
						ok-title="OK"
						centered
						size="lg"
						title="Size Chart"
					>
						<div class="size_guide_popup">
							<div class="col-12">
								<h1>SIZE GUIDE</h1>
								<p>CLOTHING</p>
							</div>
							<div class="col-12">

								<table>
									<tr>
										<th>Size</th>
										<th>XXS</th>
										<th>XS</th>
										<th>S</th>
										<th>M</th>
										<th>L</th>
										<th>XL</th>
										<th>XXL</th>
										<th>XXXL</th>
										<th>XXXXL</th>
										<th>XXXXXL</th>
										<th>XXXXXXL</th>
									</tr>
									<tr>
										<th>NUMERICAL</th>
										<td>00</td>
										<td>0</td>
										<td>1</td>
										<td>2</td>
										<td>3</td>
										<td>4</td>
										<td>5</td>
										<td>6</td>
										<td>7</td>
										<td>8</td>
										<td>9</td>
									</tr>
									<tr>
										<th>UK</th>
										<td>4</td>
										<td>6</td>
										<td>8</td>
										<td>10</td>
										<td>12</td>
										<td>14</td>
										<td>16</td>
										<td>18</td>
										<td>20</td>
										<td>22</td>
										<td>24</td>
									</tr>
									<tr>
										<th>US</th>
										<td>0</td>
										<td>2</td>
										<td>4</td>
										<td>6</td>
										<td>8</td>
										<td>10</td>
										<td>12</td>
										<td>14</td>
										<td>16</td>
										<td>18</td>
										<td>20</td>
									</tr>
									<tr>
										<th>IT</th>
										<td>36</td>
										<td>38</td>
										<td>40</td>
										<td>42</td>
										<td>44</td>
										<td>46</td>
										<td>48</td>
										<td>50</td>
										<td>52</td>
										<td>54</td>
										<td>56</td>
									</tr>
									<tr>
										<th>FR</th>
										<td>32</td>
										<td>34</td>
										<td>36</td>
										<td>38</td>
										<td>40</td>
										<td>42</td>
										<td>44</td>
										<td>46</td>
										<td>48</td>
										<td>50</td>
										<td>52</td>
									</tr>
								</table>

							</div>
						</div>
					</b-modal>

					<b-modal
						ref="ref_size_modal_male"
						id="size_modal_male"
						cancel-variant="primary"
						ok-only
						ok-title="OK"
						centered
						size="lg"
						title="Size Chart"
					>
						<div class="size_guide_popup">
							<div class="col-12">
								<h1>SIZE GUIDE</h1>
								<p>CLOTHING</p>
							</div>
							<div class="col-12">
								<table>
									<thead>
										<tr>
										<td>Size</td>
										<td>Chest (inches)</td>
										<td>Waist (inches)</td>
										<td>Neck (inches)</td>
										<td>Hip (inches)</td>
										</tr>
									</thead>
									<tbody>
										<tr>
										<td>XS</td>
										<td>36</td>
										<td>28-30</td>
										<td>14</td>
										<td>36.5-38.5</td>
										</tr>
										<tr>
										<td>S</td>
										<td>38</td>
										<td>30-32</td>
										<td>15</td>
										<td>38.5-40.5</td>
										</tr>
										<tr>
										<td>M</td>
										<td>40</td>
										<td>32-34</td>
										<td>16</td>
										<td>40.5-41.5</td>
										</tr>
										<tr>
										<td>L</td>
										<td>42</td>
										<td>34-36</td>
										<td>17</td>
										<td>41.5-42.5</td>
										</tr>
										<tr>
										<td>XL</td>
										<td>44</td>
										<td>36-38</td>
										<td>18</td>
										<td>42.5-43.5</td>
										</tr>
										<tr>
										<td>XXL</td>
										<td>46</td>
										<td>38-40</td>
										<td>19</td>
										<td>43.5-44.5</td>
										</tr>
										<tr>
										<td>3XL</td>
										<td>48</td>
										<td>40-42</td>
										<td>20</td>
										<td>44.5-46.5</td>
										</tr>
										<tr>
										<td>4XL</td>
										<td>50</td>
										<td>42-44</td>
										<td>21</td>
										<td>46.5-48.5</td>
										</tr>
										<tr>
										<td>5XL</td>
										<td>52</td>
										<td>44-46</td>
										<td>22</td>
										<td>48.5-50.5</td>
										</tr>
										<tr>
										<td>6XL</td>
										<td>54</td>
										<td>46-48</td>
										<td>23</td>
										<td>50.5-52.5</td>
										</tr>
									</tbody>
								</table>

							</div>
						</div>
					</b-modal>

					<!-- <div id="myModal" class="modal fade" role="dialog">
						<div
							class="modal-dialog"
							style="margin-top: 40px !important; width: 80% !important"
						>
							<div class="modal-content">
								<div
									class="modal-header"
									style="background-color: #fff !important"
								>
									<button type="button" class="close" data-dismiss="modal">
										×
									</button>
									<h4 class="modal-title">Size Chart</h4>
								</div>
								<div class="modal-body" style="">
									
								</div>
								<div
									class="modal-footer"
									style="background-color: #fff !important"
								>
									<button
										type="button"
										class="btn btn-default"
										data-dismiss="modal"
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div> -->

					<!-- Size Chart Modal -->
					<b-modal
						ref="ref-modal-lg-choose-celebrity"
						id="modal-lg-choose-celebrity"
						cancel-variant="primary"
						ok-only
						ok-title="Save"
						centered
						size="md"
						title="Choose Celebrity"
					>
						<b-form-input
							type="text"
							placeholder="Search/Enter Celebrity"
							v-model="keyword"
						/>

						<b-table
							id="CelebrityListTableId"
							ref="refCelebrityListTableId"
							:items="celebrityList"
							responsive
							:fields="celebrityColumns"
							primary-key="name"
							class="position-relative celebrity_table"
							:keyword="keyword"
						>
							<!-- @click="chooseCelebrity(data.item)" -->
							<template #cell(name)="data">
								<span class="text-black">
									<b-button
										v-ripple.400="'rgba(186, 191, 199, 0.15)'"
										variant="flat-secondary"
										class="choose_btn"
										@click="chooseCelebrity(data.item)"
									>
										{{ data.item }}
									</b-button>
								</span>
							</template>
						</b-table>
					</b-modal>

					<!-- Size Chart Modal -->
					<b-modal
						id="modal-lg-size-chart"
						cancel-variant="primary"
						ok-only
						ok-title="OK"
						centered
						size="lg"
						title="Size Chart"
						>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsam
						repellendus possimus earum nisi quae adipisci explicabo molestiae
						velit excepturi fuga corporis et consequuntur quasi blanditiis,
						maiores commodi sequi quam facilis.

						<b-img
							src="https://assets.myntassets.com/assets/images/sizechart/2016/12/12/11481538267795-footwear.png"
						/>
					</b-modal>

					<!-- Condition Legend - Modal -->
					<b-modal
						id="condition-modal"
						cancel-variant="secondary"
						ok-only
						ok-title="OK"
						centered
						size="lg"
						title="Condition Legend"
					>
						<b-table class="mt-2" responsive="sm" :items="condition_items" />
					</b-modal>

					<!-- <b-modal
            id="size_modal"
            cancel-variant="secondary"
            ok-only
            ok-title="OK"
            centered
            size="lg"
            title="Size Legend"
          >
            <b-form-checkbox v-model="selected_s" value="A">
              SM
            </b-form-checkbox>
            <b-form-checkbox v-model="selected_s" value="B">
              MD
            </b-form-checkbox>
            <b-form-checkbox v-model="selected_s" value="C">
              L
            </b-form-checkbox>
            <b-form-checkbox v-model="selected_s" value="D">
              XL
            </b-form-checkbox>
            <b-form-checkbox v-model="selected_s" value="E">
              XXL
            </b-form-checkbox>
          </b-modal> -->
				</b-form>
			</b-card>
		</validation-observer>
		<b-row>
			<b-col class="text-center">
				<a href="/">Back to Home</a>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BFormInput,
	BFormGroup,
	BForm,
	BRow,
	BCard,
	BCol,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BCardText,
	BFormTextarea,
	BFormRadio,
	BInputGroup,
	BButtonGroup,
	BInputGroupPrepend,
	BInputGroupAppend,
	BFormDatepicker,
	BImg,
	BFormFile,
	BCollapse,
	VBToggle,
	VBPopover,
	BFormSelect,
	BFormSelectOption,
	BCardBody,
	BAlert,
	BFormCheckbox,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import ProductServices from "@/apiServices/ProductServices";
import axios from "axios";
//import VueAxios from "vue-axios";
import storageService from "@/apiServices/storageService";
import DropDownServices from "@/apiServices/DropDownServices";
import { country_code } from "../Address/countries_json";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserManagementServices from "@/apiServices/UserManagementServices";
import { designers } from "./designers";
import { FILESURL, STRIPE_PUB_KEY } from "@/config/index";
import { loadScript } from "@/libs/helpers";
import MasterServices from "@/apiServices/MasterServices";
import AuthServices from "@/apiServices/AuthServices";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//import Vue from "vue";

//Vue.use(VueAxios, axios);

export default {
	metaInfo: {
		title: "Create Item | Revivify",
		htmlAttrs: {
			lang: "en-US",
		},
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
		BCardText,
		BCardBody,
		BCard,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BFormTextarea,
		BFormRadio,
		BButtonGroup,
		BInputGroup,
		BInputGroupPrepend,
		BInputGroupAppend,
		BFormDatepicker,
		BImg,
		BFormFile,
		BCollapse,
		BFormSelect,
		VBPopover,
		VBToggle,
		vSelect,
		BFormSelectOption,
		AppCollapse,
		AppCollapseItem,
		Loading,
		BAlert,
		BFormCheckbox,
		//VueAxios,
	},

	directives: {
		Ripple,
		"b-toggle": VBToggle,
		"b-popover": VBPopover,
	},

	computed: {
		celebrityList() {
			return this.keyword
				? this.celebrities.filter((item) => {
						if (item) {
							if (
								item
									.toString()
									.toLowerCase()
									.includes(this.keyword.toString().toLowerCase())
							) {
								return true;
							} else {
								return false;
							}
						}
				  })
				: this.celebrities;
		},
	},

	data() {
		const conditions = ["New", "Excellent", "Very Good", "Good", "Fair"];

		const currencies = ["£", "$", "₹"];

		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const minDate = new Date(today);
		minDate.setHours(minDate.getHours() + 336);

		return {
			isMaleMainCategorySelected: false,
			isFeMaleMainCategorySelected: false,
			selected_tnc: "",
			mainCategory: ["Men", "Women", "Fashion Jewellery", "Accessories"],
			subCategoryMen: [
				"Kurtas",
				"Bandis",
				"Sherwanis",
				"Shoes",
				"Shirts",
				"Suits",
				"Bandhgalas",
				"Mens Jewellery",
			],
			subCategoryFJ: [
				"Earrings",
				"Necklaces",
				"Rings",
				"Tikkas & Passas",
				"Bangles & Bracelets",
				"Brooches",
				"Nose rings",
				"Hand harness",
				"Head harness",
			],
			subCategoryWomen: [
				"Lehengas",
				"Anarkalis",
				"Kurtas",
				"Shararas",
				"Saris",
				"Gowns",
				"Dresses",
				"Tops",
				"Kaftans",
				"Jumpsuits",
				"Skirts",
				"Jackets",
				"Capes",
				// "Beachwear",
				"Co-ords",
			],
			subCategoryAcc: ["Shawls", "Bags", "Belts"],
			currentSubCategory: [],

			collapseType: "margin",

			STRIPE_PUB_KEY,
			confirmParams: {
				return_url: window.location.href, // success url
			},

			/* Fullscreen Loader Start*/
			status: false,
			isLoading: false,
			/* Fullscreen Loader Ends*/

			countries: [],
			city_list: [],
			cityModel: "",
			coutryCodeSelected: "",
			countryCode: country_code,

			countryModel: "",
			cityModel: "",
			countrySelectedName: "",

			cloth_sizes: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "Free Size"],
			charities: [
				"Wellcome Trust",
				"Azim Premji Foundation",
				"Mastercard Foundation",
				"Lilly Endowment",
				"Ford Foundation",
			],
			celebrities: [
				"Joe Biden",
				"Cristiano Ronaldo",
				"Robert Downey",
				"Jeff Bezos",
				"Kylie Jenner",
			],

			celebrityColumns: [
				{ key: "name", label: "Celebrity Name", sortable: false },
			],
			keyword: "",
			select_celebrity: "Choose Cebelrity",

			item_forms: [
				{
					itemType: "sell",
					mainCategorySelected: "",
					typeSelected: "",
					designerSelected: "",
					conditionSelected: "",
					color: "",
					countryModel: "",
					cityModel: "",
					currenciesAmountSelected: null,
					currenciesSymbolSelected: null,
					amount: "",
					date_of_purchase: "",
					product_description: "",
					service_id: "",
					service: null,
					service_base_price: 0,
					service_converted_price: 0,
					currenciesBudgetSelected: "£",
					budget: "",
					delivery_date: "",
					images: {
						imgF: null,
						imgB: null,
						imgS: null,
						imgL: null,
						imgD: null,
					},
					frontImage: "",
					backImage: "",
					insideImage: "",
					labelImage: "",
					defectImage: "",
					currentSymbol: "₹",
					receipt: "",

					charity: "",
					celebrity: "",

					charitySelected: false,
					celebritySelected: false,

					frontImageRequired: null,
					backImageRequired: null,
					sideImageRequired: null,
					labelImageRequired: null,
				},
			],
			nextItemId: 1,

			//itemType: "sell", //Sell - Refubishment
			designers, //ARRAY Designers
			conditions, //ARRAY - GOOD, OK, GREAT
			currencies,
			sellIsRequired: true,
			refurbishIsRequired: false,
			sellerSelected: "",
			sellerList: [],
			min: minDate,
			colors: [
				{
					title: "White",
					icon: "CircleIcon",
					color_code: "#ffffff",
				},
				{
					title: "Black",
					icon: "CircleIcon",
					color_code: "#000000",
				},
				{
					title: "Blue",
					icon: "CircleIcon",
					color_code: "#326FDA",
				},
				{
					title: "Brown",
					icon: "CircleIcon",
					color_code: "#7E4E38",
				},
				{
					title: "Burgundy",
					icon: "CircleIcon",
					color_code: "#953E47",
				},
				{
					title: "Cream",
					icon: "CircleIcon",
					color_code: "#ECEAC1",
				},
				{
					title: "Ecru",
					icon: "CircleIcon",
					color_code: "#DED4C8",
				},
				{
					title: "Gold",
					icon: "CircleIcon",
					color_code: "#AF812C",
				},
				{
					title: "Grey",
					icon: "CircleIcon",
					color_code: "#949494",
				},
				{
					title: "Red",
					icon: "CircleIcon",
					color_code: "#F40002",
				},
				{
					title: "Green",
					icon: "CircleIcon",
					color_code: "#596750",
				},
				{
					title: "Ivory",
					icon: "CircleIcon",
					color_code: "#FFFFF0",
				},
				{
					title: "Neutrals",
					icon: "CircleIcon",
					color_code: "#E6C3AF",
				},
				{
					title: "Orange",
					icon: "CircleIcon",
					color_code: "#F08213",
				},
				{
					title: "Pink",
					icon: "CircleIcon",
					color_code: "#F058A3",
				},
				{
					title: "Purple",
					icon: "CircleIcon",
					color_code: "#803EAC",
				},
				{
					title: "Silver",
					icon: "CircleIcon",
					color_code: "#C0C0C0",
				},
				{
					title: "Yellow",
					icon: "CircleIcon",
					color_code: "#FFFF96",
				},
				{
					title: "Animal Print",
					icon: "CircleIcon",
					color_code: "#111111",
				},
			],
			condition_items: [
				{
					title: "New",
					description:
						"Never used, stored perfectly, no signs of wear or tear. May come with original tags.",
				},
				{
					title: "Excellent",
					description: "Excellent: Well stored, with minimal signs of usage.",
					//description: "Appears unworn. Hardly ever been used and nearly no signs of wear.",
				},
				{
					title: "Very Good",
					description:
						"Minimal use with small signs of wear and tear that are only visible up close.",
				},
				{
					title: "Good",
					description:
						"Some signs of use, slight staining and wear at borders or rims.",
				},
				{
					title: "Fair",
					description:
						"Visible usage, minor stains, wear and tear but good enough to wear.",
				},
			],
			tempCurrenciesSymbol: null,
			//currentSymbol: "£",
			//service_base_price: 0,

			clothType: [],
			designersList: [],
			conditionsList: [],

			type: "FRONT",
			imgIndex: "",

			userType: "",
			sellerId: 0,
			sellerEmail: "",
			sellerVerified: true,
			sellerPayoutVerified: false,

			imagesValidation: null,
			userInfo: null,
		};
	},

	watch: {
		"$store.state.home.currency": {
			handler: function (val) {
				if (this.$store.state.home.currency.length > 0) {
					this.item_forms[0].currenciesAmountSelected =
						this.$store.state.home.currency[2].c_id;
					this.item_forms[0].currenciesSymbolSelected =
						this.$store.state.home.currency[2].c_symbol;
				}
			},
			deep: true,
			immediate: true,
		},

		currenciesAmountSelected: function (oldVal, newVal) {
			if (this.amount) {
				let oldCurrencyObj = this.getCurrencyObj(oldVal);
				let newCurrencyObj = this.getCurrencyObj(newVal);

				let amt = parseInt(this.amount);
				let rupee_conversion =
					amt / parseInt(oldCurrencyObj.c_rupee_conversion);
				let new_amt =
					rupee_conversion * parseInt(newCurrencyObj.c_rupee_conversion);

				this.amount = Math.round(new_amt);
			}
		},
	},

	methods: {

		showSizeModal(index){
			if(this.item_forms[index].mainCategorySelected == 'Men'){
				this.$refs["ref_size_modal_male"].toggle("#toggle-btn");
			}else if(this.item_forms[index].mainCategorySelected == 'Women'){
				this.$refs["ref_size_modal_female"].toggle("#toggle-btn");
			}
		},

		loadAllUsers() {
			UserManagementServices.getUsers()
				.then((response) => {
					this.sellerList = response.data.data;
				})
				.catch((error) => {
					console.error("There was an error!", error);
				});
		},

		checkAccountVerified(email) {
			const payload = { email: email };
			AuthServices.checkAccountVerified(payload)
				.then((response) => {
					let resp = response.data.data;
					console.log("checkAccountVerified:", resp);
					if (resp.verified_status) {
						this.sellerVerified = true;
					} else {
						this.sellerVerified = false;
					}
				})
				.catch((error) => {
					console.error("There was an error!", error);
				});
		},

		chooseCelebrity(celeb_name) {
			if (celeb_name != "") {
				this.keyword = celeb_name;
			} else {
				this.keyword = "Choose Celebrity";
			}
			this.$refs["ref-modal-lg-choose-celebrity"].hide();
		},

		repeateAgain() {
			let new_form = {
				itemType: "sell",
				mainCategorySelected: "",
				typeSelected: "",
				designerSelected: "",
				conditionSelected: "",
				color: "",
				countryModel: "",
				cityModel: "",
				currenciesAmountSelected: this.$store.state.home.currency[1].c_id,
				currenciesSymbolSelected: this.$store.state.home.currency[1].c_symbol,
				amount: "",
				date_of_purchase: "",
				product_history: "",
				service_id: "",
				service: null,
				service_base_price: 0,
				service_converted_price: 0,
				currenciesBudgetSelected: "£",
				budget: "",
				delivery_date: "",
				images: {
					imgF: null,
					imgB: null,
					imgS: null,
					imgL: null,
					imgD: null,
				},
				frontImage: "",
				backImage: "",
				insideImage: "",
				labelImage: "",
				defectImage: "",
				currentSymbol: "₹",
				receipt: "",

				charity: "",
				celebrity: "",

				charitySelected: false,
				celebritySelected: false,

				frontImageRequired: null,
				backImageRequired: null,
				sideImageRequired: null,
				labelImageRequired: null,
			};

			this.item_forms.push(new_form);
		},

		removeItem(index) {
			this.item_forms.splice(index, 1);
		},

		setImgType(type, index) {
			this.type = type;
			this.imgIndex = index;
			this.$refs["image-input"].click();
		},

		loadDefault() {
			let profileData = storageService.UserData.getUserProfile();
			let uid = JSON.parse(profileData);

			this.userInfo = uid;

			if (uid.user_role_id == 1) {
				this.userType = "SELLER";
			} else {
				this.userType = "ADMIN";
			}

			if (uid.user_verified == 1) {
				this.sellerVerified = true;
			} else {
				this.sellerVerified = false;
			}

			if (uid.user_phone_code == "+91") {
				if (uid.user_is_payu_pending == 1) {
					this.sellerPayoutVerified = true;
				} else {
					this.sellerPayoutVerified = false;
				}
			} else {
				if (uid.user_is_stripe_pending == 1) {
					this.sellerPayoutVerified = true;
				} else {
					this.sellerPayoutVerified = false;
				}
			}

			console.log("uid: ", { uid });
		},

		async loadItemFormData() {
			let response = await DropDownServices.loadItemFormData();
			//console.log(response.data, "loadItemFormData");
			if(response.status){
				this.mainCategory = response.data.mainCategory;
				this.subCategoryMen = response.data.subCategoryMen;
				this.subCategoryFJ = response.data.subCategoryFJ;
				this.subCategoryWomen = response.data.subCategoryWomen;
				this.subCategoryAcc = response.data.subCategoryAcc;
				this.cloth_sizes = response.data.cloth_sizes;
				this.charities = response.data.charities;
				this.celebrities = response.data.celebrities;
				this.colors = response.data.colors;
			}
		},

		async loadCountry() {
			let response = await DropDownServices.loadAllCountries();
			//console.log(response, "loadCountry");
			this.countries = response.data;
		},

		async showCity(country_obj) {
			if (country_obj != null) {
				this.city_list = [];
				this.cityModel = "";
				this.isLoading = true;
				let response = await DropDownServices.loadCity();
				this.city_list = response.data.filter((x) => {
					return x.country_id == country_obj.id;
				});
				this.isLoading = false;
			}
		},

		/* loadItemType() {
      DropDownServices.loadItemType()
        .then((response) => {
          this.clothType = response.data.data;
          this.loadDefault();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    }, */

		async loadDropdowns(tag_type_name) {
			try {
				const response = await MasterServices.getUserTags({ tag_type_name });
				if (response.data.status) {
					if (tag_type_name == "ItemType") {
						this.clothType = response.data.data;
					} else if (tag_type_name == "Designer") {
						this.designersList = response.data.data;
					} else if (tag_type_name == "Condition") {
						this.conditionsList = response.data.data;
					}
				}
			} catch (err) {
				console.log("Error in getting all tags ", err);
			}
		},

		beforeMountFun() {
			this.loadDefault();
			this.loadAllUsers();
			this.loadItemFormData();
			this.loadDropdowns("ItemType");
			this.loadDropdowns("Designer");
			this.loadDropdowns("Condition");
			this.loadCountry();
			this.checkAccountVerified(this.userInfo.user_email);
			if (this.userInfo.user_role_id == 1) {
				this.loadChat();
			}
		},

		changeItemType(type, index) {
			this.item_forms[index].itemType = type;
		},

		uploadNewImage(event) {
			let type = this.type;
			let imgIndex = this.imgIndex;

			let URL = process.env.VUE_APP_FILESURL; //FILESURL;

			let data = new FormData();
			event.target.files.forEach((item) => {
				data.append("doc", item);
			});

			let token = localStorage.getItem("token");
			if (token) {
				console.log("upload_trying... hitting axios", { URL, data });
				axios
					.put(URL, data, {
						headers: {
							Authorization: token,
							"content-type": "multipart/form-data",
							"Access-Control-Allow-Origin": "*",
						},
					})
					.then((response) => {
						let img_arr = response.data.data;
						if (img_arr && img_arr.length > 0) {
							for (let i = 0; i < img_arr.length; i++) {
								const item = img_arr[i];

								//this.item_forms[imgIndex].images.push(item);
								//console.log(this.item_forms[imgIndex].images);

								if (type == "FRONT") {
									this.item_forms[imgIndex].frontImage = item;
									this.item_forms[imgIndex].images.imgF = item;
								} else if (type == "BACK") {
									//this.backImage = item;
									this.item_forms[imgIndex].backImage = item;
									this.item_forms[imgIndex].images.imgB = item;
								} else if (type == "INSIDE") {
									//this.insideImage = item;
									this.item_forms[imgIndex].insideImage = item;
									this.item_forms[imgIndex].images.imgS = item;
								} else if (type == "LABEL") {
									//this.labelImage = item;
									this.item_forms[imgIndex].labelImage = item;
									this.item_forms[imgIndex].images.imgL = item;
								} else if (type == "DEFECT") {
									//this.defectImage = item;
									this.item_forms[imgIndex].defectImage = item;
									this.item_forms[imgIndex].images.imgD = item;
								}
							}
						}
					});
			} else {
				console.log("Auth Token not found ");
			}
		},

		removeNewImage(type, index) {
			if (type == "FRONT") {
				this.item_forms[index].frontImage = "";
				this.item_forms[index].images.imgF = null;
			}

			if (type == "BACK") {
				this.item_forms[index].backImage = "";
				this.item_forms[index].images.imgB = null;
			}

			if (type == "INSIDE") {
				this.item_forms[index].insideImage = "";
				this.item_forms[index].images.imgS = null;
			}

			if (type == "LABEL") {
				this.item_forms[index].labelImage = "";
				this.item_forms[index].images.imgL = null;
			}

			if (type == "DEFECT") {
				this.item_forms[index].defectImage = "";
				this.item_forms[index].images.imgD = null;
			}
		},

		uploadReceipt(event, index) {
			let URL = process.env.VUE_APP_FILESURL;

			let data = new FormData();
			event.target.files.forEach((item) => {
				data.append("doc", item);
			});

			let token = localStorage.getItem("token");
			if (token) {
				axios
					.put(URL, data, {
						headers: {
							Authorization: token,
							"content-type": "multipart/form-data",
							"Access-Control-Allow-Origin": "*",
						},
					})
					.then((response) => {
						let img_arr = response.data.data;
						if (img_arr && img_arr.length > 0) {
							for (let i = 0; i < img_arr.length; i++) {
								const item = img_arr[i];

								//this.images.push(item);
								console.log(item);

								this.item_forms[index].receipt = item;
							}
						}
					});
			} else {
				console.log("Auth Token not found ");
			}
		},

		submitForm(type) {
			this.item_forms.forEach((element, index) => {
				if (element.frontImage == "") {
					console.log(index + "Front Image Required");
					this.item_forms[index].frontImageRequired = true;
					this.imagesValidation = true;
				} else {
					this.item_forms[index].frontImageRequired = false;
					this.imagesValidation = false;
				}

				if (element.backImage == "") {
					console.log(index + "Back Image Required");
					this.item_forms[index].backImageRequired = true;
					this.imagesValidation = true;
				} else {
					this.item_forms[index].backImageRequired = false;
					this.imagesValidation = false;
				}

				if (element.insideImage == "") {
					console.log(index + "Side Image Required");
					this.item_forms[index].sideImageRequired = true;
					this.imagesValidation = true;
				} else {
					this.item_forms[index].sideImageRequired = false;
					this.imagesValidation = false;
				}

				if (element.labelImage == "") {
					console.log(index + "Label Image Required");
					this.item_forms[index].labelImageRequired = true;
					this.imagesValidation = true;
				} else {
					this.item_forms[index].labelImageRequired = false;
					this.imagesValidation = false;
				}
			});

			for (let index = 0; index < this.item_forms.length; index++) {
				const element = this.item_forms[index];

				if (element.frontImage == "") {
					this.imagesValidation = true;
					this.toastImage("Front Image", index + 1);
					return false;
				} else if (element.backImage == "") {
					this.imagesValidation = true;
					this.toastImage("Back Image", index + 1);
					return false;
				} else if (element.insideImage == "") {
					this.imagesValidation = true;
					this.toastImage("Side Image", index + 1);
					return false;
				} else if (element.labelImage == "") {
					this.imagesValidation = true;
					this.toastImage("Label Image", index + 1);
					return false;
				}
			}

			this.$refs.itemFormsValidator.validate().then((success) => {
				if (success) {
					this.$refs["ref_term_condition_accept"].toggle("#toggle-btn");
				}
			});

			if (type == "save") {
				let profileData = storageService.UserData.getUserProfile();
				let uid = JSON.parse(profileData);

				if (uid.user_role_id == 1) {
					this.sellerId = uid.user_id;
					this.sellerEmail = uid.user_email;
				}

				if (uid.user_role_id >= 2) {
					this.sellerId = this.sellerSelected.user_id;
					this.sellerEmail = this.sellerSelected.user_email;

					if (this.sellerId == null || this.sellerEmail == null) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Please select seller",
								icon: "userIcon",
								variant: "error",
							},
						});
						return;
					}
				}

				let senData = {
					productId: null,
					sellerId: this.sellerId,
					sellerEmail: this.sellerEmail,
					itemDetails: this.item_forms,
					itemSaveType: type,
				};

				/* if (senData.sellerId == 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please select seller",
                icon: "userIcon",
                variant: "error",
              },
            });
            return;
          }

          console.log("senData:", senData);
          return; */

				ProductServices.SaveProduct(senData)
					.then((response) => {
						if (response.data.status) {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "Item Saved Successfully!",
									icon: "BoxIcon",
									variant: "success",
								},
							});

							if (uid.user_role_id == 1) {
								this.$router.push("/product_list");
							} else {
								this.$router.push("/admin-items");
							}
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "Failed ot created Item",
									icon: "BoxIcon",
									variant: "error",
								},
							});
						}
					})
					.catch((error) => {
						this.errorMessage = error.message;
						console.error("There was an error!", error);
					});
			}
		},

		draftForm(type) {
			console.log(this.item_forms);

			let profileData = storageService.UserData.getUserProfile();
			let uid = JSON.parse(profileData);

			if (uid.user_role_id == 1) {
				this.sellerId = uid.user_id;
				this.sellerEmail = uid.user_email;
			}

			if (uid.user_role_id == 2) {
				this.sellerId = this.sellerSelected.user_id;
				this.sellerEmail = this.sellerSelected.user_email;
			}

			let senData = {
				productId: null,
				sellerId: this.sellerId,
				sellerEmail: this.sellerEmail,
				itemDetails: this.item_forms,
				itemSaveType: type,
			};

			// console.log("draft: ", senData);
			// return;

			ProductServices.SaveProduct(senData)
				.then((response) => {
					if (response.data.status) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Item Saved Successfully!",
								icon: "BoxIcon",
								variant: "success",
							},
						});

						this.$router.push("/product_list");
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Failed ot created Item",
								icon: "BoxIcon",
								variant: "error",
							},
						});
					}
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error("There was an error!", error);
				});
		},

		sendVerification() {
			this.isLoading = true;
			let sendData = { email: this.userInfo.user_email };
			AuthServices.resendVerivicaftionLink(sendData)
				.then((response) => {
					if (response.data.status) {
						this.isLoading = false;

						this.$toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: "UserIcon",
								variant: "success",
							},
						});
					} else {
						this.isLoading = false;

						this.$toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: "UserIcon",
								variant: "error",
							},
						});
					}
				})
				.catch((error) => {
					console.error("There was an error!", error);
					this.isLoading = false;
				});
		},

		compareServiceAmount() {
			const service_amount = this.service.service_base_price;
			const buddget_amount = this.budget;
			if (service_amount < buddget_amount) {
				return true;
			} else {
				return false;
			}
		},

		setServiceId(e, index) {
			if (e) this.item_forms[index].service_id = e.service_id;
			this.convertCurrency(index);
			// console.log(this.service_id); AJAY
		},

		getCurrencyObj(c_id) {
			let currency_arr = this.$store.state.home.currency;
			if (currency_arr.length > 0) {
				for (let i = 0; i < currency_arr.length; i++) {
					const currency = currency_arr[i];
					if (currency.c_id === c_id) {
						return currency;
					}
				}
			}
		},

		getSelectedCurrency(data, index) {
			console.log("getSelectedCurrency => ", data);
			console.log("getSelectedCurrency => ", this.$store.state.home.currency);

			let cid = data;
			let allCurrencies = this.$store.state.home.currency; //this.tempCurrenciesSymbol;
			let resCurrency = allCurrencies.filter((x) => {
				return x.c_id == cid;
			});
			this.item_forms[index].currentSymbol = resCurrency[index].c_symbol;
			//console.log(this.currentSymbol);
		},

		chooseMaincategory(data) {
			this.currentSubCategory = [];
			if (data == "Men") {
				this.currentSubCategory = this.subCategoryMen;
			} else if (data == "Women") {
				this.currentSubCategory = this.subCategoryWomen;
			} else if (data == "Accessories") {
				this.currentSubCategory = this.subCategoryAcc;
			} else if (data == "Fashion Jewellery") {
				this.currentSubCategory = this.subCategoryFJ;
			} else {
				this.currentSubCategory = [];
			}
		},

		getSuffix(i) {
			var j = i % 10,
				k = i % 100;
			if (j == 1 && k != 11) {
				return i + "st";
			}
			if (j == 2 && k != 12) {
				return i + "nd";
			}
			if (j == 3 && k != 13) {
				return i + "rd";
			}
			return i + "th";
		},

		toastImage(image_type, position) {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: image_type + " is required in Item form " + position,
					icon: "BoxIcon",
					variant: "error",
				},
			});
		},

		convertCurrency(index) {
			let getCurrency = this.item_forms[index].currenciesBudgetSelected;

			if (getCurrency == "£") {
				this.item_forms[index].service_converted_price = Math.round(
					this.item_forms[index].service.service_base_price
				).toFixed(2);
			} else if (getCurrency == "$") {
				this.item_forms[index].service_converted_price = Math.round(
					this.item_forms[index].service.service_base_price * 1.23456
				).toFixed(2);
			} else if (getCurrency == "₹") {
				this.item_forms[index].service_converted_price = Math.round(
					this.item_forms[index].service.service_base_price * 95
				).toFixed(2);
			}
		},

		capitalizeSentance(strings) {
			var splitString = strings.split(".");
			console.log(splitString.length);
			let finalDescp = null;
			for (let index = 0; index < splitString.length; index++) {
				const sentance = splitString[index].trim();
				if (index == 0) {
					finalDescp = this.capitalizeFL(sentance);
				} else {
					finalDescp = finalDescp + ". " + this.capitalizeFL(sentance);
				}
			}
			console.log(finalDescp);
		},

		capitalizeFL(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		getBasePrice(type) {
			// type = USDINR / GBPUSD / GPBINR
			const datetime = Date.now();
			const url =
				"https://query1.finance.yahoo.com/v8/finance/chart/" +
				type +
				"=X?symbol=" +
				type +
				"%3DX&period1=" +
				(datetime - 60 * 86400) +
				"&period2=" +
				datetime +
				"&interval=1d&includePrePost=false&events=div%7Csplit%7Cearn&lang=en-US&region=US&corsDomain=finance.yahoo.com";
			axios.post(url).then((response) => {
				console.log("getBasePrice: ", response);
			});
		},

		onCancel() {
			console.log("Seller cancelled the loader.");
		},

		loadChat() {
			this.interval = setInterval(() => {
				if (window.MessageBirdChatWidget) {
					window.MessageBirdChatWidget.show();
					clearInterval(this.interval);
				}
			}, 500);
		},
	},

	beforeMount() {
		this.beforeMountFun();
		//this.getBasePrice("USDINR");
		this.$store.dispatch("home/setCurrency");
		this.$store.dispatch("home/setServices");
	},
};
</script>

<style>
.item_forms_card > .collapse-margin > .card {
	border-bottom: 1px dashed #9a9a9a !important;
}

.info_icon_1 {
	transform: translateY(30%) !important;
}

.revivify-success {
	background-color: #3a4b43 !important;
}

.item_forms_card {
	border: 1px solid #3a4b43;
	border-radius: 20px;
}

.color-icon {
	border: 1px solid black !important;
	border-radius: 100px !important;
}

.choose-images-card {
	border: #ccc 1px dashed;
	text-align: center;
	color: #666;
	transition: all 0.3s ease;
	height: 210px;
	width: 100%;
	position: relative;
	border-radius: 8px !important;
	background-color: #f8f8f8 !important;
	margin-bottom: 15px !important;
}

.choose-images-card h5 {
	display: flex;
	font-size: 12px;
	color: #adadad;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	justify-content: center;
}

.choose-images-card input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	opacity: 0;
	/* -ms-filter: 'alpha(opacity=0)'; */
	font-size: 200px !important;
	direction: ltr;
	cursor: pointer;
}

.choose-images-card img {
	width: 100%;
	margin: 0px;
	height: 100%;
	padding: 2px;
	/* border-radius: 12px !important; */
}

.choose-images-card span.delete {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	align-items: center;
	align-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	justify-content: center;
}

.choose-images-card .card-body {
	padding: 0px !important;
}

span.delete .remove-icon {
	background: rgb(238, 238, 238, 0.75);
	padding: 20px;
	cursor: pointer;
}

.prod-image {
	width: 100% !important;
}

.notify-badge {
	position: absolute;
	right: -20px;
	top: 10px;
	background: red;
	text-align: center;
	border-radius: 30px 30px 30px 30px;
	color: white;
	padding: 5px 10px;
	font-size: 20px;
}

.custom-container {
	position: relative;
}

.info-icon {
	position: absolute;
	top: 0;
	right: 0;
	top: 50%;
	margin-right: 10px;
	transform: translateY(-50%);
	z-index: 1;
}

.size-chart {
	padding-left: 5px;
	padding-right: 5px;
	border: 1px solid black;
	margin-left: 20px;
	font-weight: 600;
	font-size: 14px;
	color: #ff8100;
	cursor: pointer;
	border-radius: 4px;
}

.custom-flex {
	display: -webkit-box;
}

.per-page-selector {
	width: 90px;
}

.vs__dropdown-toggle > .vs__open-indicator {
	fill: grey !important;
}

[dir="ltr"] .vs__actions > svg {
	fill: grey !important;
}

.position-relative {
	max-height: 220px !important;
	height: 220px !important;
	overflow-y: scroll;
	scroll-behavior: smooth;
	margin-bottom: 0px;
}

table#CelebrityListTableId thead tr th {
	display: none;
}

table#CelebrityListTableId tbody tr td {
	padding: 2px;
}

.choose_btn {
	width: 100%;
	text-align: left;
}
.size_guide_popup table{
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}
.size_guide_popup table th, .size_guide_popup table td {
  padding: 10px !important;
  border: 1px solid #e8e8e8 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>
